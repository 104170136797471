:root {
	font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;

	color-scheme: light dark;
	color: rgba(255, 255, 255, 0.87);
	background-color: #242424;

	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;

	margin: 0;
	display: flex;
	place-items: center;
	min-width: 320px;
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

a {
	font-weight: 500;
	color: #ab3cffaa;
	text-decoration: inherit;
}
a:hover {
	color: #535bf2;
}

h1 {
	font-size: 3.2em;
	line-height: 1.1;
}

button {
	border-radius: 8px;
	border: 1px solid transparent;
	padding: 0.6em 1.2em;
	font-size: 1em;
	font-weight: 500;
	font-family: inherit;
	background-color: #1a1a1a;
	cursor: pointer;
	transition: border-color 0.25s;
}
button:hover {
	border-color: #ab3cffaa;
}
button:focus,
button:focus-visible {
	outline: 4px auto -webkit-focus-ring-color;
}

.card {
	padding: 2em;
}

.bottom {
	color: #888;
	font-size: small;
}

.discordBtn {
	margin: .5em;
}

.loginBtn {
	margin: .5em;
}

.logoutBtn {
	margin: .5em;
}

@media (prefers-color-scheme: light) {
	:root {
		color: #213547;
		background-color: #ffffff;
	}
	a:hover {
		color: #747bff;
	}
	button {
		background-color: #f9f9f9;
	}
}
